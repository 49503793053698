<template>
  <div class="p-3">
    <p>{{ message }}</p>
  </div>
</template>

<script>
import { firebase } from '@/main'

export default {
  data() {
    return {
      redirectTo: this.$route.query.redirect_to || '/',
      message: null,
    }
  },
  mounted() {
    const loading = this.$buefy.loading.open()

    firebase.auth().signInAnonymously().then(() => {
      loading.close()
      this.$router.push(this.redirectTo)
    })
    .catch((error) => {
      this.message = `${error.code}, ${error.message}`
      console.log(this.message)
      loading.close()
    })
  }
}
</script>
